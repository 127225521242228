










import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
