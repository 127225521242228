
































import Vue, { PropType } from "vue";
import LoaderComponent from "../components/LoaderComponent.vue";
import { setScrollBarColor } from "../lib/util";

export default Vue.extend({
  components: { LoaderComponent },
  props: {
    appBarColor: {
      type: String as PropType<string>,
      default: "primary",
    },
    dark: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  mounted() {
    setScrollBarColor("light");
  },
});
