















import Vue from "vue";
import AppLayout from "../layouts/AppLayout.vue";
import FooterComponent from "../components/FooterComponent.vue";

export default Vue.extend({
  components: { AppLayout, FooterComponent },
  async created() {
    try {
      // start loading
      this.$store.commit("loading/set", true);

      // set user token & info
      const token = this.$route.params.token;
      await this.$store.dispatch("user/setToken", token);

      // goto application
      this.$router.push("/step");
    } catch (e) {
      // notify error
      console.error(e);
      this.$alert(e.message || e);
    } finally {
      // stop loading
      this.$store.commit("loading/set", false);
    }
  },
});
