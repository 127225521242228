import { render, staticRenderFns } from "./StepperComponent.vue?vue&type=template&id=af415b72&scoped=true&"
import script from "./StepperComponent.vue?vue&type=script&lang=ts&"
export * from "./StepperComponent.vue?vue&type=script&lang=ts&"
import style0 from "./StepperComponent.vue?vue&type=style&index=0&id=af415b72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af415b72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VStepper,VStepperContent,VStepperStep})
