import { Module } from "vuex";
import { MessageState, RootState } from "./types";

export const message: Module<MessageState, RootState> = {
  namespaced: true,

  state: () => ({
    template: "",
  }),

  mutations: {
    set(state, template) {
      state.template = template;
    },
  },
};
