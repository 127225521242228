import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VuetifyConfirm from "vuetify-confirm";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3A1140",
        secondary: "#29A65F",
        accent: "#F2AB27",
        error: "#D91A4D",
        warning: "#F2AB27",
        info: "#35D0F2",
        success: "#29A65F",
      },
    },
  },
});

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "OK",
  buttonFalseText: null,
  property: "$alert",
  persistent: true,
});

export default vuetify;

declare module "vue/types/vue" {
  interface Vue {
    $alert: (msg: string) => Promise<any>;
  }
}
