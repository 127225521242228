import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { user } from "./user";
import { loading } from "./loading";
import { recipients } from "./recipients";
import { message } from "./message";

export default new Vuex.Store({
  modules: {
    user,
    loading,
    recipients,
    message,
  },
});
