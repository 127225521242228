


import Vue from "vue";
export default Vue.extend({
  mounted() {
    this.$store.dispatch("user/clearToken");
    this.$router.push("/");
  },
});
