








import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    color: {
      type: String as PropType<string>,
      default: "primary",
    },
  },
});
