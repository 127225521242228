import { render, staticRenderFns } from "./Step.vue?vue&type=template&id=f64f870e&scoped=true&"
import script from "./Step.vue?vue&type=script&lang=ts&"
export * from "./Step.vue?vue&type=script&lang=ts&"
import style0 from "./Step.vue?vue&type=style&index=0&id=f64f870e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f64f870e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBarTitle,VAvatar,VImg,VSpacer})
