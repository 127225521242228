const API_BASE = "https://slack.com/api/";

export class API {
  private token: string;

  constructor(token: string) {
    this.token = token;
  }

  // API call
  async post(path: string, params: any = {}): Promise<any> {
    const res = await window.fetch(API_BASE + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        token: this.token,
        ...params,
      }),
    });

    // fetch and validate
    const out = await res.json();
    if (!out.ok) throw new Error(out.error);
    return out;
  }
}

// common options for chat.postMessage API
export const postMessageOptions = {
  unfurl_links: true,
  unfurl_media: true,
  link_names: true,
};
