import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "../views/Home.vue";
import Logout from "../views/Logout.vue";
import Token from "../views/Token.vue";
import Step from "../views/Step.vue";
import Recipients from "../views/step/Recipients.vue";
import Message from "../views/step/Message.vue";
import Send from "../views/step/Send.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/token/:token",
    component: Token,
  },
  {
    path: "/step",
    component: Step,
    children: [
      {
        path: "/",
        redirect: "/step/recipients",
      },
      {
        path: "recipients",
        component: Recipients,
      },
      {
        path: "message",
        component: Message,
      },
      {
        path: "send",
        component: Send,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
