












































































import Vue from "vue";
import TopLayout from "../layouts/TopLayout.vue";
import FooterComponent from "../components/FooterComponent.vue";

export default Vue.extend({
  components: { TopLayout, FooterComponent },
  methods: {
    authUrl() {
      return (
        "/authorize?" +
        new URLSearchParams({
          scope: "users:read users:read.email chat:write:user",
        })
      );
    },
  },
});
