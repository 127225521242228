



























import Vue from "vue";
import AppLayout from "../layouts/AppLayout.vue";
import UserInfoComponent from "../components/UserInfoComponent.vue";
import StepperComponent from "../components/StepperComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";

export default Vue.extend({
  components: {
    AppLayout,
    UserInfoComponent,
    FooterComponent,
    StepperComponent,
  },
  data() {
    return {
      stepper: [
        {
          title: "Recipients",
          path: "/step/recipients",
        },
        {
          title: "Message",
          path: "/step/message",
        },
        {
          title: "Send Messages!",
          path: "/step/send",
          disableLink: true,
        },
      ],
    };
  },
  mounted() {
    if (!this.$store.state.user.token) this.$router.push("/");
  },
});
