

























































import Vue, { PropType } from "vue";
import { API, postMessageOptions } from "../lib/slack";
import { sleep } from "../lib/util";
import { RecipientsState } from "../store/types";

type DataType = {
  i: number;
};

export default Vue.extend({
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data(): DataType {
    return {
      i: 0,
    };
  },
  computed: {
    recipients(): RecipientsState["list"] {
      return (this.$store.state.recipients as RecipientsState).list;
    },
  },
  methods: {
    async send(): Promise<void> {
      try {
        // start loading
        this.$store.commit("loading/set", true);

        // preparation
        const p = this.recipients[this.i];
        const token = this.$store.state.user.token;
        const api = new API(token);

        // wait and send
        await sleep(500);
        await api.post("chat.postMessage", {
          channel: `@${this.$store.state.user.user}`,
          text: p.message,
          ...postMessageOptions,
        });

        // notify completion
        this.$alert(
          "Test message sent to you. Check the direct message from yourself in the Slack app."
        );
      } catch (e) {
        // notify errors
        console.error(e);
        this.$alert(e.message || e);
      } finally {
        // finish loading
        this.$store.commit("loading/set", false);
      }
    },
  },
});
