import { RecipientsState } from "../store/types";
type RecipientType = RecipientsState["list"][0];

// replace variables in template message
export function compileTemplate(
  template: string,
  recipient: RecipientType
): string {
  const keys: (keyof RecipientType)[] = ["variable1", "variable2", "variable3"];
  let out = template;
  for (const key of keys) {
    const from = "__" + key.toUpperCase() + "__";
    const to = recipient[key] || "";
    out = out.replaceAll(from, to);
  }
  return out;
}
