





































import Vue from "vue";
import { HotTable } from "@handsontable/vue";
import { HotTableProps } from "@handsontable/vue/types";
import Handsontable from "handsontable";
import MainLayout from "../../layouts/MainLayout.vue";
import CardComponent from "../../components/CardComponent.vue";
import { RecipientsState } from "../../store/types";
import { sleep } from "../../lib/util";

type DataType = {
  tableSettings: HotTableProps["settings"];
};
type RowDataType = [
  string | null,
  string | null,
  string | null,
  string | null,
  string | null
];
type RecipientType = RecipientsState["list"][0];

const NUM_COLS = 5;
const MIN_SPARE_ROWS = 20;

export default Vue.extend({
  components: { HotTable, MainLayout, CardComponent },
  data(): DataType {
    return {
      tableSettings: {
        data: [],
        height: 400,
        width: "100%",
        stretchH: "all",
        allowInsertColumn: false,
        minSpareRows: MIN_SPARE_ROWS,
        colWidths: `${100 / NUM_COLS}%`,
        columns: [{}, {}, {}, {}, { readOnly: true }],
        colHeaders: [
          "Recipient",
          "Variable1",
          "Variable2",
          "Variable3",
          "Format error",
        ],
        licenseKey: "non-commercial-and-evaluation",
      },
    };
  },
  methods: {
    // load from $store and apply to table
    load(): void {
      const data: NonNullable<HotTableProps["settings"]>["data"] = [];
      const cell: NonNullable<HotTableProps["settings"]>["cell"] = [];

      (this.$store.state.recipients as RecipientsState).list.forEach((r) => {
        // convert
        data.push([
          r.usernameType === "SLACK" ? `@${r.username}` : r.username,
          r.variable1,
          r.variable2,
          r.variable3,
          r.usernameError,
        ]);

        // hilight errors
        if (r.usernameType === "ERROR") {
          for (let i = 0; i < NUM_COLS; i++) {
            cell.push({
              row: data.length - 1,
              col: i,
              className: "cell-error",
            });
          }
        }
      });

      // add spare rows
      for (let i = 0; i < MIN_SPARE_ROWS; i++) {
        data.push(["", "", "", "", null]);
      }

      // apply to table
      const table = this.table();
      table.updateSettings({ data, cell });
    },

    // save table data to $store with validation
    save(): void {
      // convert
      const table = this.table();
      const recipients = table.getData().map(
        (row: RowDataType): Partial<RecipientType> => ({
          username: row[0] || "",
          variable1: row[1] || "",
          variable2: row[2] || "",
          variable3: row[3] || "",
        })
      );

      // save and validate
      this.$store.dispatch("recipients/set", recipients);

      // load data
      this.load();
    },

    // do validation and show alert dialogs
    alertValidation(): boolean {
      // save to $store with validation
      this.save();

      // check if empty
      const recipients = (this.$store.state.recipients as RecipientsState).list;
      if (recipients.length === 0) {
        this.$alert("The list is empty.");
        return false;
      }

      // check if error exists
      const errorCount = recipients.filter(
        (r) => r.usernameType === "ERROR"
      ).length;
      if (errorCount > 0) {
        const error = errorCount > 1 ? `${errorCount} errors` : "1 error";
        this.$alert(`${error} found. Confirm recipient usernames.`);
        return false;
      }

      // return true if ok
      return true;
    },

    // ref of handsontable instance
    table(): Handsontable {
      return (this.$refs.hot as any).hotInstance;
    },

    // on "Validate" button clicked
    onValidate(): void {
      this.save();
      if (this.alertValidation()) this.$alert("No errors found.");
    },

    // on "Next" button clicked
    onNext(): void {
      this.save();
      if (this.alertValidation()) this.$router.push("/step/message");
    },
  },
  async created(): Promise<void> {
    this.$store.commit("loading/set", true);
    await sleep(500);
    this.$store.commit("loading/set", false);
    this.load();
  },
});
