


















import Vue, { PropType } from "vue";

type StepType = {
  title: string;
  path: string;
  disableLink: boolean | undefined;
};

export default Vue.extend({
  props: {
    value: {
      type: Array as PropType<StepType[]>,
    },
  },
  computed: {
    // current step number
    iStep(): number {
      const index = this.value.findIndex((step) => {
        return step.path === this.$route.path;
      });
      if (index >= 0) return index + 1;
      return 0;
    },

    // 'disabledLink' value on current step
    linkDisabled(): boolean {
      const step = this.value.find((step) => {
        return step.path === this.$route.path;
      });
      if (!step) return false;
      return !!step.disableLink;
    },

    // v-on trigger object for each step
    von(): unknown {
      return this.value.map((step, i) => {
        if (this.linkDisabled) return null;
        if (i < this.iStep - 1)
          return {
            click: () => {
              this.$router.push(step.path);
            },
          };
        else return null;
      });
    },
  },
});
