






















































import Vue from "vue";
import { HotTable } from "@handsontable/vue";
import { HotTableProps } from "@handsontable/vue/types";
import Handsontable from "handsontable";
import { API, postMessageOptions } from "../../lib/slack";
import { sleep } from "../../lib/util";
import MainLayout from "../../layouts/MainLayout.vue";
import CardComponent from "../../components/CardComponent.vue";
import { RecipientsState } from "../../store/types";

type RecipientType = {
  username: string;
  usernameType: string;
  slackUsername: string;
  message: string;
  status: "" | "FINDING_USER" | "SENDING" | "SUCCESS" | "ERROR";
  error: string | null;
};
type DataType = {
  state: "NONE" | "SENDING" | "SENT" | "ABORTED";
  recipients: RecipientType[];
  iCursor: number;
  tableSettings: HotTableProps["settings"];
};
type RowDataType = [string, string, RecipientType["status"], string];

const NUM_COLS = 4;

export default Vue.extend({
  components: { HotTable, MainLayout, CardComponent },

  data(): DataType {
    return {
      state: "NONE",
      recipients: [],
      iCursor: 0,

      tableSettings: {
        data: [],
        width: "100%",
        stretchH: "all",
        allowInsertColumn: false,
        allowInsertRow: false,
        wordWrap: false,
        colWidths: ["20%", "45%", "15%", "20%"],
        colHeaders: ["Recipient", "Message", "Status", "Error"],
        columns: new Array(NUM_COLS).fill({ readOnly: true }),
        licenseKey: "non-commercial-and-evaluation",
      },
    };
  },
  methods: {
    async send(): Promise<void> {
      // state guard
      if (!["ABORTED", "NONE"].includes(this.state)) return;

      // initialize
      const api = new API(this.$store.state.user.token);
      this.state = "SENDING";

      // loop
      while (
        this.state === "SENDING" &&
        this.iCursor < this.recipients.length
      ) {
        // set target
        const target: RecipientType = this.recipients[this.iCursor];

        try {
          // get slack-username
          if (target.usernameType === "SLACK") {
            target.slackUsername = target.username;
          } else if (target.usernameType === "EMAIL") {
            // get username via slack api

            // pre-waiting
            target.status = "FINDING_USER";
            this.updateTable();
            await sleep(1000);

            // call api
            const resLookup = await api.post("users.lookupByEmail", {
              email: target.username,
            });
            target.slackUsername = resLookup.user.name;
          } else {
            // unknown type of username
            target.status = "ERROR";
            target.error = "Invalid username format.";
            continue;
          }

          // pre-waiting
          target.status = "SENDING";
          this.updateTable();
          await sleep(1000);

          // call api
          await api.post("chat.postMessage", {
            channel: `@${target.slackUsername}`,
            text: target.message,
            ...postMessageOptions,
          });
          target.status = "SUCCESS";
        } catch (e) {
          // set error for target
          target.status = "ERROR";
          target.error = e.message || e;
        } finally {
          // update table
          this.updateTable();

          // for next iteration
          this.iCursor += 1;
        }
      }
      // check completion
      if (this.iCursor === this.recipients.length) {
        this.state = "SENT";

        // show dialog
        const countError = this.recipients.filter(
          (r) => r.status === "ERROR"
        ).length;
        if (countError === 0) {
          this.$alert("Messages all sent!");
        } else {
          const error = countError === 1 ? "1 error" : `${countError} errors`;
          this.$alert(
            `Sending completed with ${error}. Please confirm the error message shown in table.`
          );
        }
      } else {
        this.$alert("Sending aborted.");
      }
    },

    // apply data to table
    updateTable(): void {
      const data: NonNullable<HotTableProps["settings"]>["data"] = [];
      const cell: NonNullable<HotTableProps["settings"]>["cell"] = [];

      this.recipients.forEach((r) => {
        // username
        let username: string;
        if (r.slackUsername) username = `@${r.slackUsername}`;
        else if (r.usernameType === "SLACK") username = `@${r.username}`;
        else username = r.username;

        // row data
        const row: RowDataType = [username, r.message, r.status, r.error || ""];
        data.push(row);

        // column styling
        if (r.status) {
          for (let i = 0; i < NUM_COLS; i++) {
            cell.push({
              row: data.length - 1,
              col: i,
              className: `cell-${r.status.toLowerCase()}`,
            });
          }
        }
      });

      // apply to table
      const table = this.table();
      table.updateSettings({ data, cell });
    },

    // load recipients from $store
    load() {
      const stored = (this.$store.state.recipients as RecipientsState).list;
      const recipients: RecipientType[] = stored.map((r) => ({
        username: r.username,
        usernameType: r.usernameType || "ERROR",
        slackUsername: "",
        message: r.message,
        status: "",
        error: null,
      }));
      this.$set(this, "recipients", recipients);
    },

    // ref of handsontable instance
    table(): Handsontable {
      return (this.$refs.hot as any).hotInstance;
    },

    // on "Send All" button clicked
    async onSendAll(): Promise<void> {
      await this.send();
    },

    // on "Abort" button clicked
    onAbort(): void {
      this.state = "ABORTED";
    },

    // on "Continue" button clicked
    async onContinue(): Promise<void> {
      await this.send();
    },
  },
  async created() {
    // re-verify recipients
    this.$store.dispatch("recipients/validate");

    // apply message template
    await this.$store.dispatch(
      "recipients/applyMessageTemplate",
      this.$store.state.message.template
    );

    // load data
    this.load();

    // update table
    this.updateTable();
  },
});
