



























import Vue, { PropType } from "vue";
import { setScrollBarColor } from "../lib/util";

export default Vue.extend({
  props: {
    colorClass: {
      type: String as PropType<string>,
      default: "primary",
    },
    dark: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  created() {
    setScrollBarColor(this.dark ? "dark" : "light");
  },
});
