import { Module } from "vuex";
import { RecipientsState, RootState } from "./types";
import { compileTemplate } from "../lib/massage-template";

// https://gist.github.com/gswalden/27ac96e497c3aa1f3230
const REGEX_SLACK_USERNAME = /^[a-z0-9][a-z0-9._-]*$/;

// https://www.w3resource.com/javascript/form/email-validation.php
const REGEX_EMAIL_ADDRESS =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

type RecipientType = RecipientsState["list"][0];

export const recipients: Module<RecipientsState, RootState> = {
  namespaced: true,

  state: () => ({
    list: [],
  }),

  mutations: {
    set(state, list: RecipientType[]) {
      state.list = list;
    },
  },
  actions: {
    // set recipients with blank check and defaults fill
    set({ commit, dispatch }, list: Partial<RecipientType>[]) {
      const recipients: RecipientType[] = [];
      list.forEach((r) => {
        // remove headed "@"
        let username: string = r.username?.trim() || "";
        if (username.startsWith("@")) username = username.substr(1);

        // exclude blank
        if (
          !username &&
          !r.variable1?.trim() &&
          !r.variable2?.trim() &&
          !r.variable3?.trim()
        ) {
          return;
        }

        // fill with defaults
        recipients.push({
          username: username,
          variable1: r.variable1 || "",
          variable2: r.variable2 || "",
          variable3: r.variable3 || "",
          usernameType: null,
          usernameError: null,
          message: r.message || "",
        });
      });

      // set and validate
      commit("set", recipients);
      dispatch("validate");
    },

    // Validate recipients username formats
    validate({ state, commit }) {
      const recipients = state.list.map((r): RecipientType => {
        // validate usernames
        let usernameType: RecipientType["usernameType"] = null;
        let usernameError: RecipientType["usernameError"] = null;

        if (REGEX_SLACK_USERNAME.test(r.username)) {
          usernameType = "SLACK";
        } else if (REGEX_EMAIL_ADDRESS.test(r.username)) {
          usernameType = "EMAIL";
        } else {
          usernameType = "ERROR";
          usernameError = `"${r.username}" is not a valid slack username or email address`;
        }

        // set
        return {
          username: r.username,
          variable1: r.variable1,
          variable2: r.variable2,
          variable3: r.variable3,
          usernameType,
          usernameError,
          message: r.message,
        };
      });
      commit("set", recipients);
    },

    // apply template for message
    applyMessageTemplate({ state, commit }, template: string) {
      const recipients = state.list;
      recipients.forEach((r) => {
        r.message = compileTemplate(template, r);
      });
      commit("set", recipients);
    },
  },
};
