















































import Vue from "vue";
import MainLayout from "../../layouts/MainLayout.vue";
import CardComponent from "../../components/CardComponent.vue";
import PreviewComponent from "../../components/PreviewComponent.vue";

export default Vue.extend({
  components: { MainLayout, CardComponent, PreviewComponent },
  data() {
    return {
      showPreview: false,
      template: "",
    };
  },
  methods: {
    // save to $store
    save() {
      this.$store.commit("message/set", this.template);
      this.$store.dispatch("recipients/applyMessageTemplate", this.template);
    },
    // load from $store
    load() {
      this.template = this.$store.state.message.template;
    },
    // on "Preview" button clicked
    onPreview() {
      this.save();
      this.showPreview = true;
    },
    // on "Next" button clicked
    onNext() {
      this.save();
      this.$router.push("/step/send");
    },
  },
  created() {
    this.load();
  },
});
