import { Module } from "vuex";
import { LoadingState, RootState } from "./types";

export const loading: Module<LoadingState, RootState> = {
  namespaced: true,

  state: () => ({
    isLoading: false,
  }),

  mutations: {
    set(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
};
