import { Module } from "vuex";
import { API } from "../lib/slack";
import { UserState, RootState } from "./types";

export const user: Module<UserState, RootState> = {
  namespaced: true,

  state: () => ({
    token: null,
    team: null,
    user: null,
    image: null,
  }),

  mutations: {
    set(state, user): void {
      state.token = user.token;
      state.team = user.team;
      state.user = user.user;
      state.image = user.image;
    },
  },

  actions: {
    clearToken({ commit }) {
      commit("set", {
        token: null,
        team: null,
        user: null,
        image: null,
      });
    },

    async setToken({ commit }, token) {
      const api = new API(token);
      const authTest = await api.post("auth.test");
      const userInfo = await api.post("users.info", { user: authTest.user_id });
      commit("set", {
        token,
        team: authTest.team,
        user: authTest.user,
        image: userInfo.user.profile.image_192,
      });
    },
  },
};
